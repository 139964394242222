.img-preview {
  border-radius: 0.4rem !important;
  box-shadow: 0 0.75rem 1.5rem var(--bs-body-color);

  @media all and (orientation:landscape) {
    display:none;
    // content: url(http://subtlepatterns.com/patterns/dark_embroidery.png) !important;
  }
}

.no-preview-in-landscape{
    display:none;
    @media all and (orientation:landscape) {
        display:block;
      }
}