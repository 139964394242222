//
// _game.scss
//

// Link Buttons
.btn-nav-rules {
  text-decoration: underline;
}

// Link Buttons
.btn-plus-minus-point,
.btn-plus-minus-point:active,
.btn-plus-minus-point:hover {
  text-decoration: none !important;
  font-size: 1.2rem;
  border-radius: 0.8em;
  border: 1px solid #b7b7b7 !important;
  padding: 8px 18px;
  &.btn-plus-point {
    padding: 8px 16px;
  }
}

.input-point {
  padding: 12px 12px;
  // margin-left: 0.5em;
  // margin-right: 0.5em;
  text-align: center;
}

.label-player-point {
  padding-top: calc(0.85rem + 2px) !important;
  text-transform: capitalize;
}

.btn-hole {
  // border-width: 1px !important;
  width: 60px;
  height: 60px;
  font-size: 1.2rem;
  // &:hover {
  //   color: #000000 ;
  // }
  &.btn-outline-dark {
    border-color: #b7b7b7 !important;
    &:not(:active):not(:hover) {
      background-color: #ffffff !important;
    }
    &:focus-visible {
      color: #000000;
    }
  }

  // background-color: #ffffff !important;
}

.game-margin {
  margin-top: -15px !important;
}

.wrap-first:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  border-left: 2px solid #b7b7b7;
  height: 100%;
  transform: translateX(100%);
  z-index: -1 !important;
}

.wrap-bottom-left:before {
  content: "";
  position: absolute;
  left: 50%;
  top: -50%;
  border-bottom: 2px solid #b7b7b7;
  border-bottom-left-radius: 50%;
  height: 100%;
  width: 100%;
  z-index: -1 !important;
}

.wrap-top-right:before {
  content: "";
  position: absolute;
  right: 50%;
  bottom: -50%;
  border-top: 2px solid #b7b7b7;
  border-top-right-radius: 50%;
  height: 100%;
  width: 100%;
  z-index: -1 !important;
}

.wrap-bottom-right:before {
  content: "";
  position: absolute;
  right: 50%;
  top: -50%;
  border-bottom: 2px solid #b7b7b7;
  border-bottom-right-radius: 50%;
  height: 100%;
  width: 100%;
  z-index: -1 !important;
}

.wrap-top-left:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  border-top: 2px solid #b7b7b7;
  border-top-left-radius: 50%;
  height: 100%;
  width: 100%;
  z-index: -1 !important;
}

.wrap-horizontal:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  border-top: 2px solid #b7b7b7;
  // background: #b7b7b7;
  width: 100%;
  transform: translateY(50%);
  z-index: -1 !important;
}

.bg-z-index {
  z-index: -1 !important;
}

.podium-game {
  box-shadow: 0px 0px 30px #dddddd;
  // padding: 0.7em 0.1em;
  border-radius: 1.2em;
  // border-bottom: 1px solid #dddddd;
}
