.border-input,
.border-input:hover,
.border-input:active,
.border-input:focus{
    border-color: rgba(var(--bs-primary-rgb), 1);
}

.btn-disabled-light{
    background-color: #cfcfcf !important;
    border: none;
}