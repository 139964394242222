//
// _table.scss
//

.table {
  th {
    font-weight: $font-weight-bold;
  }
}

.table-dark > :not(:last-child) > :last-child > * {
  border-bottom-color: $table-dark-border-color;
}

.table-bordered {
  border: $table-border-width solid $table-border-color;
}

.table-nowrap {
  th,
  td {
    white-space: nowrap;
  }
}

.table > :not(:first-child) {
  border-top: 0;
}

.table-glf {
  min-width:500px;
  white-space: nowrap;
  margin: 0;
  // border: none;
  // border-collapse: separate;
  // border-spacing: 0;
  // table-layout: fixed;
  // border: 1px solid black;

  // td,
  // th {
    // border: 1px solid black;
    // padding: 0.5rem 1rem;
  // }

  thead th {
    // padding: 3px;
    position: sticky;
    top: 0;
    z-index: 1;
    // width: 25vw;
    background: white;
  }

  tfoot td {
    // padding: 3px;
    position: sticky;
    bottom: 0;
    z-index: 1;
    // width: 25vw;
    background: white;
  }


  td {
    background: #fff;
    // padding: 4px 5px;
    text-align: center;
  }

  tbody th {
    font-weight: 100;
    font-style: italic;
    position: relative;
  }

  thead th:first-child{
    position: sticky;
    left: 0;
    background-color: #FFFFFF;
    z-index: 3;
  }
  td:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
  }

  tbody th {
    position: sticky;
    background: white;
    z-index: 1;
  }
}

[role="region"][aria-labelledby][tabindex] {
  min-width: 100%;
  max-height: 80vh;
  overflow: auto;
}
[role="region"][aria-labelledby][tabindex]:focus {
  // box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
  outline: 0;
}
