//
// _game.scss
//

.podium {
  background-color: $success;
  color: $white;
  font-size: 1rem;
  border-top-right-radius: 15pt;
  border-top-left-radius: 15pt;
  border-bottom-left-radius: 0pt;
  border-bottom-right-radius: 0pt;
  
  .points {
    position: absolute;
    bottom: 0.7rem;
    left: 50%;
    transform: translateX(-50%);
  }

  &.first {
    margin-top: 1rem;
    height: 10rem;
    .btn {
      position: absolute;
      top: -4.8rem;
      border-radius: 100%;
      font-size: 1.2rem;
      padding: 1rem 1.4rem;
      left: 50%;
      transform: translateX(-50%);
    }
    .name {
      position: absolute;
      color: $black;
      right: 0;
      left: 0;
      margin: auto;
      top: -0.8rem;
    }
  }

  &.second {
    margin-top: 4rem;
    height: 7rem;
    .btn {
      position: absolute;
      top: -0.8rem;
      border-radius: 100%;
      padding: 0.8rem 1rem;
      left: 50%;
      transform: translateX(-50%);
    }
    .name {
      position: absolute;
      color: $black;
      right: 0;
      left: 0;
      margin: auto;
      top: 2.2rem;
    }
  }

  &.third {
    margin-top: 6rem;
    height: 5rem;
    .btn {
      position: absolute;
      top: 1.2rem;
      border-radius: 100%;
      padding: 0.8rem 1rem;
      left: 50%;
      transform: translateX(-50%);
    }
    .name {
      position: absolute;
      color: $black;
      right: 0;
      left: 0;
      margin: auto;
      top: 4.2rem;
    }
  }
}

input[type="file"][name="photo"]{
    display: none;
}

.custom-file-upload-photo {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}  

.modal-footer-grid{
  display: block !important;
}